import {
  Msg
} from "@tencent/universe-ui/build/lib";
// import JSZip from 'jszip';

export const isLogin = (e) => {
  const { QCLogin } = window;
  console.log('isLogin==>', !!QCLogin.isLogin())
  if (!!QCLogin.isLogin()) {
    return true;
  } else {
    e?.stopPropagation();
    QCLogin.showLoginBox();
    return false;
  }
}
export const opentree = tree => {
  let result = [];
  const flat = nodes => {
    if (nodes && nodes.length > 0)
      nodes.forEach(node => {
        result.push({ title: node.title, id: node.id, parentId: node.parentId });
        flat(node.children);
      });
  };
  flat(tree);
  return result;
};

export const DownloadExcel = (base64ExcelData) => {
  const raw = window.atob(base64ExcelData);
  const uInt8Array = new Uint8Array(raw.length);
  for (let i = 0; i < raw.length; i++) {
    uInt8Array[i] = raw.charCodeAt(i);
  }
  const link = document.createElement('a');
  const blob = new Blob([uInt8Array], {
    type: 'application/vnd.ms-excel',
  });
  link.style.display = 'none';
  link.href = URL.createObjectURL(blob);
  link.setAttribute('download', '结果表格.xlsx');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const DownloadMd = (base64ExcelData, type, fileName) => {
  const raw = window.atob(base64ExcelData);
  const uInt8Array = new Uint8Array(raw.length);
  for (let i = 0; i < raw.length; i++) {
    uInt8Array[i] = raw.charCodeAt(i);
  }
  const link = document.createElement('a');
  const blob = new Blob([uInt8Array], {
    type: type === 'zip' ? 'data:application/zip;base64' : 'data:text/markdown;base64',
  });

  link.style.display = 'none';
  link.href = URL.createObjectURL(blob);
  link.setAttribute('download', `${fileName}.${type}`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const DownloadMpZip = (MarkdownBase64, InsetImagePackage) => {

  const getBlob = (base64, type) => {
    const raw = window.atob(base64);
    const uInt8Array = new Uint8Array(raw.length);
    for (let i = 0; i < raw.length; i++) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    const blob = new Blob([uInt8Array], {
      type: type
    });
    return blob;
  }
  const zip = new JSZip();
  // 添加一个文件
  zip.file('myBlog.md', getBlob(MarkdownBase64, 'data:text/markdown;base64'));
  zip.file('myBlog.zip', getBlob(InsetImagePackage, 'data:application/zip;base64'));
  // 生成 Blob 和导出 zip 文件
  zip.generateAsync({ type: 'blob' }).then(blob => {
    // 创建一个链接
    const link = document.createElement('a');
    // 将 blob 作为链接的内容
    link.href = URL.createObjectURL(blob);
    // 设置链接的下载名称
    link.download = 'file.zip';
    // 点击链接
    link.click();
  }).catch(() => {
    alert('错误');
  });
};

export function OcrTable(data) {
  try {
    let brCol;
    let brRow;
    let occupyCol;
    let occupyRow;
    let rows = 0;
    let cols = 0;
    var temp = [];
    let perRow = [];
    var rowArr = [];
    let headerContentArr = [];
    let footerContentArr = [];
    let headerNum = 0;
    let footerNum = 0;

    if (data.Cells) {
      let textDetections = data.Cells;
      for (let i = 0; i < textDetections.length; i++) {
        if (textDetections[i].Type === 'body') {
          brCol = textDetections[i].ColBr;
          brRow = textDetections[i].RowBr;
          if (brRow > rows) {
            rows = brRow;
          }
          if (brCol > cols) {
            cols = brCol;
          }
        } else if (textDetections[i].Type === 'header') {
          headerNum += 1;
          headerContentArr.push({
            headerContent: textDetections[i].Text,
            cols: cols,
          });
        } else if (textDetections[i].Type === 'footer') {
          footerNum += 1;

          footerContentArr.push({
            footerContent: textDetections[i].Text,
            cols: cols,
          });
        }
      }

      for (let h = 0; h < headerContentArr.length; h++) {
        if (headerContentArr.length !== 0) {
          rowArr.push({
            tl_row: h,
            tl_col: 0,
            br_row: h + 1,
            br_col: rows,
            cell_content_text: headerContentArr[h].headerContent,
            occupyCol: cols,
            occupyRow: 1,
            rows: rows + headerNum + footerNum,
          });

          perRow[h] = rowArr;
          rowArr = [];
        }
      }

      for (let l = 0; l < rows; l++) {
        for (let i = 0; i < textDetections.length; i++) {
          let curTextDetections = textDetections[i];
          if (curTextDetections.Type === 'body') {
            if (textDetections[i].RowTl === l) {
              if (headerContentArr.length !== 0) {
                var headerLength = headerContentArr.length;
                occupyCol = textDetections[i].ColBr - textDetections[i].ColTl;
                occupyRow = textDetections[i].RowBr - textDetections[i].RowTl;
                rowArr.push({
                  tl_row: textDetections[i].RowTl + headerLength,
                  tl_col: textDetections[i].ColTl,
                  br_row: textDetections[i].RowBr + headerLength,
                  br_col: textDetections[i].ColBr,
                  cell_content_text: textDetections[i].Text,
                  occupyCol: occupyCol,
                  occupyRow: occupyRow,
                  rows: rows + headerNum + footerNum,
                });

                perRow[textDetections[i].RowTl + headerLength] = rowArr;
              } else {
                occupyCol = textDetections[i].ColBr - textDetections[i].ColTl;
                occupyRow = textDetections[i].RowBr - textDetections[i].RowTl;
                rowArr.push({
                  tl_row: textDetections[i].RowTl,
                  tl_col: textDetections[i].ColTl,
                  br_row: textDetections[i].RowBr,
                  br_col: textDetections[i].ColBr,
                  cell_content_text: textDetections[i].Text,
                  occupyCol: occupyCol,
                  occupyRow: occupyRow,
                  rows: rows + headerNum + footerNum,
                });

                perRow[textDetections[i].RowTl] = rowArr;
              }
            }
          }
        }
        rowArr = [];
      }
    }

    for (let k = 0; k < perRow.length; k++) {
      for (let m = 0; m < perRow[k].length - 1; m++) {
        for (let n = 0; n < perRow[k].length - 1 - m; n++) {
          if (perRow[k][n].tl_col > perRow[k][n + 1].tl_col) {
            temp = perRow[k][n];
            perRow[k][n] = perRow[k][n + 1];
            perRow[k][n + 1] = temp;
          }
        }
      }
    }

    for (let i = 0; i < footerContentArr.length; i++) {
      var hLength = headerContentArr.length;
      rowArr.push({
        tl_row: i + rows + hLength,
        tl_col: 0,
        br_row: i + rows + hLength,
        br_col: rows,
        cell_content_text: footerContentArr[i].footerContent,
        occupyCol: cols,
        occupyRow: 1,
        rows: rows + headerNum + footerNum,
      });
      perRow[i + rows + hLength] = rowArr;
      rowArr = [];
    }
    return perRow;
  } catch (err) {
    return []
  }
};
export function transEdu(result) {
  let arr = [];
  const TypeMap = ['Question', 'Option', 'Figure', 'Table', 'Answer']
  TypeMap.forEach(v => {
    arr.push(result[v].map((i, dIdx) => {
      return {
        ...i,
        type: v,
        dIdx: dIdx
      }
    }))
    arr.sort((a, b) => a.Index - b.Index)
  })
  return arr.flat()
}
export function transV2(result) {
  try {
    if (!result) {
      return {
        rects: [],
        list: [],
        table: []
      }
    }
    let list = [], table = [], rects = [];
    result.forEach(item => {
      if (item.Groups.length > 1 || item.Groups[0]?.Lines?.length > 1) {
        // 处理table
        // 处理table
        let _header = item.Groups.map(i => i.Lines).flat().map(j => j.Key.AutoName);
        _header = Array.from(new Set(_header))
        let tableBody = new Array(item.Groups.length); //表格有10行
        for (var i = 0; i < tableBody.length; i++) {
          tableBody[i] = new Array(_header.length).fill(''); //每行有10列
        }
        item.Groups.map((l, tr) => {
          l.Lines.map((_item, td) => {
            let colIndex = _header.indexOf(_item.Key.AutoName)
            tableBody[tr][colIndex] = _item.Value.AutoContent
          })
        })
        table.push({
          header: _header,
          content: tableBody
        })
      } else {
        // 处理kv
        item.Groups.forEach(l => {
          l.Lines.forEach(_item => {
            const _ItemCoord = _item.Value.Coord;
            list.push({
              originItemCoord:_ItemCoord,
              ItemCoord: {
                X: _ItemCoord?.LeftBottom?.X,
                Y: _ItemCoord?.LeftTop?.Y,
                Height: _ItemCoord?.RightBottom?.Y - _ItemCoord?.RightTop?.Y,
                Width: _ItemCoord?.RightBottom?.X - _ItemCoord?.LeftBottom?.X
              },
              Name: _item.Key.AutoName,
              Value: _item.Value.AutoContent
            })
          })
        })
      }
      // 处理标记rect
      item.Groups.forEach(l => {
        l.Lines.forEach(_item => {
          const _ItemCoord = _item.Value.Coord;
          rects.push({
            originItemCoord:_ItemCoord,
            ItemCoord: {
              X: _ItemCoord?.LeftBottom?.X,
              Y: _ItemCoord?.LeftTop?.Y,
              Height: _ItemCoord?.RightBottom?.Y - _ItemCoord?.RightTop?.Y,
              Width: _ItemCoord?.RightBottom?.X - _ItemCoord?.LeftBottom?.X
            },
            Name: _item.Key.AutoName,
            Value: _item.Value.AutoContent
          })
        })
      })
    });

    return {
      list,
      rects,
      table
    };
  } catch (err) {
    return {
      rects: [],
      list: [],
      table: []
    };
  }
};

/**
 * @description 复制文字内容到剪切板
 */

export const copyText = function (text) {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text).then(res => {
      Msg.success({
        message: '复制成功'
      });
    });
  } else {
    var textarea = document.createElement('textarea');
    document.body.appendChild(textarea);
    // 隐藏此输入框
    textarea.style.position = 'fixed';
    textarea.style.clip = 'rect(0 0 0 0)';
    textarea.style.top = '10px';
    // 赋值
    textarea.value = text;
    // 选中
    textarea.select();
    // 复制
    document.execCommand('copy', true);
    // 移除输入框
    document.body.removeChild(textarea);
    Msg.sucess({
      message: '复制成功'
    });
  }
};

// 获取url参数
export function getQueryString(name) {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i');
  const r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return decodeURIComponent(r[2]);
  };
  return null;
};

export const getScaleRatio = ({ ImageBox, Image }) => {
  const wRatio = ImageBox?.w / Image.w;
  const hRatio = ImageBox?.h / Image.h;
  const ratio = hRatio < wRatio ? hRatio : wRatio; // 得出容器的大小与图片大小的比例
  return {
    Ratio: ratio,
    ImageWidth: Image.w * ratio,
    ImageHeight: Image.h * ratio,
  };
};

export function getImgNatural(url,isPdf) {
  return new Promise(async (resolve, reject) => {
    if(!url){
      resolve({
          width: 0,
          height: 0
        });
  } 
    const temImg = new Image();
    temImg.src = url;

    temImg.onload = function () {
      console.log('实际尺寸：',temImg.width, temImg.height);
      resolve({
        // width: isPdf ? temImg.width * 1.33 : temImg.width,
        // height: isPdf ? temImg.height * 1.33 : temImg.height,
        width: temImg.width,
        height:  temImg.height,
      });
    };
    temImg.onerror = function (err) {
      console.log('任务图片识别失败', err)
      reject('任务图片识别失败');
    };
  });
};

/**
 * 处理因为特殊字符导致的编码原因
 * @param {*} char 字符串
 * @returns
 */
export const encodeSpecialChar = (char) => {
  const encodeArr = [{
    code: '%',
    encode: '%25'
  }, {
    code: '?',
    encode: '%3F'
  }, {
    code: '#',
    encode: '%23'
  }, {
    code: '&',
    encode: '%26'
  }, {
    code: '=',
    encode: '%3D'
  }]
  return char.replace(/[%?#&=]/g, ($) => {
    for (const k of encodeArr) {
      if (k.code === $) {
        return k.encode
      }
    }
  })
}

export const parseFloatPrecision = (num, precision = 12) => {
  return +parseFloat(num.toPrecision(precision));
};

/**
 * @param nodes 要过滤的树节点集（多根）
 * @param predicate 过滤条件，返回 `true` 保留
 * @returns 过滤后的树节点集
 */
export const filterTreeNode = (nodes, predicate) => {
  if (!nodes?.length) { return nodes; }

  // 直接使用 Array 的 filter 可以过滤当层节点
  return nodes.filter(it => {
    if (!predicate(it)) {
      it.children = filterTree(it.children, predicate);
    } else {
      return true
    }
  });
}

export const filterTree = (arr, predicate) => {
  let newarr = [];
  arr.forEach(element => {
    if (predicate(element)) { // 判断条件
      newarr.push(element);
    } else {
      if (element.children && element.children.length > 0) {
        let redata = filterTree(element.children, predicate);
        if (redata && redata.length > 0) {
          let obj = {
            ...element,
            children: redata
          };
          newarr.push(obj);
        }
      }
    }
  });
  return newarr;
}

export const getSum = (arr) => {
  return eval(arr.join("+"));
}
export function GetProperty(obj, str) {
  str = str.replace(/\[(\w+)\]/g, ".$1"); // 处理数组下标
  let arr = str.split(".");
  for (let i in arr) {
    obj = obj[arr[i]] || "";
  }
  return obj;
}
export const getRectPosition = ({ data, config,isPdf }) => {
  console.log('isPdf',isPdf)
  if(isPdf && config.cantShowPdfRect) return [] //  pdf 页码没有返回，不支持画框显示
  try{
  let _rects = [];
  if (config) {
    let StructuralList = config.StructuralList;
    let RectKey = config.RectKey;
    let ValKey = config.ValKey;
    if (config.positionType === 'edu') {
      const getQuestionCoord = (detail, dep) => {
        detail.forEach((item, questionId) => {
          if (item.Coord) { //  整题坐标
            let _ItemCoord = item.Coord[0];
            _rects.push(
              {
                visible: false,
                cantSelect: true,
                fill: 'transparent',
                activeFill: 'rgba(0, 110, 255, 0.12)',
                stroke: '#AACFFF',
                activeStoke: '#006eff',
                key: `Question${questionId.toString()}${dep.toString()}`,
                OriginImgHeight: data.QuestionInfo[0].OrgHeight,
                OriginImgWidth: data.QuestionInfo[0].OrgWidth,
                points: [
                  _ItemCoord?.LeftTop?.X || 0, _ItemCoord?.LeftTop?.Y || 0,
                  _ItemCoord?.RightTop?.X || 0, _ItemCoord?.RightTop?.Y || 0,
                  _ItemCoord?.RightBottom?.X || 0, _ItemCoord?.RightBottom?.Y || 0,
                  _ItemCoord?.LeftBottom?.X || 0, _ItemCoord?.LeftBottom?.Y || 0
                ]
              }
            );
          }
          const TypeMap = ['Question', 'Option', 'Figure', 'Table', 'Answer']
          TypeMap.forEach(v => {
            if (item[v]?.length < 0) return;
            item[v].forEach((que, index) => {              
              let _ItemCoord = que.Coord;
              _rects.push(
                {
                  visible: false,
                  cantSelect: true,
                  fill: 'transparent',
                  stroke: 'transparent', //  默认不显示，颜色置空
                  activeFill: '#663AFA1F',
                  activeStoke: '#663AFA',
                  key: `result${questionId}${v}${que.Index.toString()}${dep.toString()}`,
                  OriginImgHeight: data.QuestionInfo[0].OrgHeight,
                  OriginImgWidth: data.QuestionInfo[0].OrgWidth,
                  points: [
                    _ItemCoord?.LeftTop?.X || 0, _ItemCoord?.LeftTop?.Y || 0,
                    _ItemCoord?.RightTop?.X || 0, _ItemCoord?.RightTop?.Y || 0,
                    _ItemCoord?.RightBottom?.X || 0, _ItemCoord?.RightBottom?.Y || 0,
                    _ItemCoord?.LeftBottom?.X || 0, _ItemCoord?.LeftBottom?.Y || 0
                  ]
                }
              );
              if (que.ResultList?.length > 0) {
                getQuestionCoord(que.ResultList,dep + 1)
              }
            })
            
          })
          
        })

      }
      getQuestionCoord(data.QuestionInfo[0].ResultList, 1)
    } else if (config.positionType === '5') {
      // RecognizeGeneralTextImageWarn
      let rect = [];
      Object.keys(data).map(key => {
        if (data[key] && data[key].Polygon && data[key].Polygon.length > 0) {
          rect = rect.concat(data[key].Polygon)
        }
      })
      _rects = rect.map(_ItemCoord => {
        return {
          visible: false,
          OriginImgHeight: _ItemCoord.OriginHeight,
          OriginImgWidth: _ItemCoord.OriginWidth,
          fill: 'transparent',
          activeFill: 'rgba(0, 110, 255, 0.12)',
          stroke: '#AACFFF',
          activeStoke: '#006eff',
          points: [
            _ItemCoord?.LeftTop?.X || 0, _ItemCoord?.LeftTop?.Y || 0,
            _ItemCoord?.RightTop?.X || 0, _ItemCoord?.RightTop?.Y || 0,
            _ItemCoord?.RightBottom?.X || 0, _ItemCoord?.RightBottom?.Y || 0,
            _ItemCoord?.LeftBottom?.X || 0, _ItemCoord?.LeftBottom?.Y || 0
          ],
        }
      })
    } else {
      const getRect = ({ data, curLength = 0, target = [] }) => {
        let _cur = curLength;
        let curDataIndex = StructuralList[_cur];
        if (curLength < StructuralList.length - 1) {
          for (let i = 0; i < data[curDataIndex]?.length; i++) {
            getRect({ data: data[curDataIndex][i], curLength: _cur + 1, target })
          }
        } else {
          for (let i = 0; i < data[curDataIndex]?.length; i++) {
            const _item = data[curDataIndex][i];
            const _ItemCoord = GetProperty(_item, RectKey);
            let points = [];
            switch (config.positionType) {
              case '1':
                points = [
                  _ItemCoord?.X || 0, _ItemCoord?.Y || 0,
                  _ItemCoord?.X + _ItemCoord?.Width || 0, _ItemCoord?.Y || 0,
                  _ItemCoord?.X + _ItemCoord?.Width || 0, _ItemCoord?.Y + _ItemCoord?.Height || 0,
                  _ItemCoord?.X || 0, _ItemCoord?.Y + _ItemCoord?.Height || 0
                ]
                break;
              case '2':
                points = [
                  _ItemCoord?.LeftTop?.X || 0, _ItemCoord?.LeftTop?.Y || 0,
                  _ItemCoord?.RightTop?.X  || 0, _ItemCoord?.RightTop?.Y || 0,
                  _ItemCoord?.RightBottom?.X  || 0, _ItemCoord?.RightBottom?.Y  || 0,
                  _ItemCoord?.LeftBottom?.X    || 0, _ItemCoord?.LeftBottom?.Y  || 0
                ]
                break;
              case '3':
                points = [
                  _ItemCoord[0]?.X || 0, _ItemCoord[0]?.Y || 0,
                  _ItemCoord[1]?.X || 0, _ItemCoord[1]?.Y || 0,
                  _ItemCoord[2]?.X || 0, _ItemCoord[2]?.Y || 0,
                  _ItemCoord[3]?.X || 0, _ItemCoord[3]?.Y || 0
                ]
                break;
            }
            target.push({
              visible: false,
              key: GetProperty(_item, ValKey) + JSON.stringify(_ItemCoord),
              points: points,
              fill: 'transparent',
              activeFill: 'rgba(0, 110, 255, 0.12)',
              stroke: '#AACFFF',
              activeStoke: '#006eff',
            })
          }
        }
        return target;
      }
      _rects = getRect({
        data
      });
    }
  }
  console.log('onResult _rects', _rects)
  return _rects;
}catch(e){
  return []
}
}

// 函数节流
export const throttle = (fn, interval = 1000) => {
  var enterTime = 0; //触发的时间
  var gapTime = interval; //间隔时间，如果interval不传，则默认2000ms
  return function (e) {
    var that = this;
    var backTime = new Date(); //第一次函数return即触发的时间
    if (backTime - enterTime > gapTime) {
      fn.call(that, e, arguments);
      enterTime = backTime; //赋值给第一次触发的时间，这样就保存了第二次触发的时间
    }
  };
}

// 函数防抖
export const debounce = (fn, wait) => {
  let timer;
  return (...args) => {
    const context = this
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      timer = null
      fn.apply(context, args)
    }, wait)
  }
}
