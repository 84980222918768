import React, { useState, useEffect, useRef, useImperativeHandle } from "react";
import { Form, Msg, Loading, Button, Tab } from "@tencent/universe-ui/build/lib";
import Params from './common/Params.jsx';
import OutputParams from './common/OutputParams.jsx';
import JsonView from 'react18-json-view'
import { baseOcrRequest } from '../utils/api.js';
import { v4 as uuidv4 } from 'uuid';
import { ErrorBoundary} from "react-error-boundary";

const reg = /^\s*$/

export default function GeneralBasicOCR({ selectRect, cRef, detail, image, onSelect, onChangeRequest, onLoading, onChangeImage, onImageError, onResult, totalPage = 50 }) {
  useImperativeHandle(cRef, () => ({
    start: ({ image }) => {
      const _smartKeyList = smartKeyList.filter(item => !item.isCustom);
      setSmartKeyList(_smartKeyList);
      handleStart(requestParamsValue, image, _smartKeyList);
    }
  }));
  
  const [paramsValue, setParamsValue] = useState({});
  const [requestParamsValue, setRequestParamsValue] = useState({});
  const [showParams, setShowParams] = useState(true);
  const [response, setResponse] = useState({});
  const [request, setRequest] = useState({});
  const [curColl, setCurColl] = useState('id2');
  const [smartKeyList, setSmartKeyList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const messagesEnd = useRef(null);
  const paramsRef = useRef(null);


  const scrollToBottom = () => {
    if (messagesEnd && messagesEnd.current) {
      messagesEnd.current.scrollIntoView({ behavior: 'smooth', block: "end", inline: "nearest" });
    }
  };


  useEffect(() => {
    if (smartKeyList?.length > 0) {
      scrollToBottom();
    }
  }, [smartKeyList]);

  useEffect(() => {
    if (!detail) return;
    setResponse({});
    setRequest({});
    setCurColl('id2');
    const _value = {};
    if (detail.paramsList?.length > 0) {
      detail.paramsList.forEach(e => {
        _value[e.key] = e.default
      });
    }
    setParamsValue({ ..._value });
    setRequestParamsValue({ ..._value });
    onChangeRequest && onChangeRequest({ ..._value });
    setSmartKeyList([]);
    handleStart(_value, image, []);
  }, [detail]);

  const handleStart = async (_params = requestParamsValue, _image = image, _smartKeyList = smartKeyList) => {
    if (!_image) return;
    if (!validate(_smartKeyList)) return;
    setResponse({});
    setRequest({});
    if (detail.action === 'SmartStructuralOCRV2' || detail.action === 'SmartStructuralPro') {
      if (_smartKeyList?.length > 0) {
        _params.ItemNames = _smartKeyList.map(i => {
          return i.isCustom ? detail.options.find(o => o.value === i.value)?.text : i.value
        }).filter(n => n);
      } else {
        _params.ItemNames = []
      }
    }

    setIsLoading(true);
    onLoading && onLoading(true);
    const res = await baseOcrRequest({
      cmd: detail.action,
      data: { ..._params, [_image.prefix]: _image.param ? _image.param : _image.url },
      isExample: _image.isExample,
      fileType: _image.fileType,
      module: detail.module || 'ocr',
    })
    setIsLoading(false);
    onLoading && onLoading(false);
    if (res.isImageError) {
      onImageError(true);
    } else {
      onImageError(false);
    }
    if (res.error) {
      Msg.error({
        message: res.message
      });
      setRequest({ ..._params, [_image.prefix]: _image.url });
      // TODO: 处理不展示结果的
      setResponse(res);
      setCurColl('id2');
    } else {
      // 处理智能结构化
      if (detail.action === 'ImageEnhancement') {
        // 处理文本图像
        onChangeImage && onChangeImage({
          type: _params.TaskType === 1 ? 'col' : 'split',
          url: res.Image
        })
      }
      setRequest({ ..._params, [_image.prefix]: _image.url });
      setResponse(res);
      onResult && onResult({
        image:_image,
        requestParams: _params,
        rsp:res
      });
      setCurColl('id2');
    }
  }

  // 智能结构化处理
  const handleAddSmart = (type) => {
    let _key = uuidv4();
    smartKeyList.push({
      isCustom: type === 'custom' ? false : true,
      showClose: true,
      type: type === 'custom' ? 'input' : 'select',
      key: _key,
      default: '',
      value: '',
      status: ''
    })
    setSmartKeyList([...smartKeyList]);
  }
  const handleDelSmart = (params, index) => {
    smartKeyList.splice(index, 1);
    setSmartKeyList([...smartKeyList]);
  }
  const validate = (_smartKeyList = smartKeyList) => {
    let result = _smartKeyList.map(item => {
      if (reg.test(item.value)) {
        return {
          ...item,
          status: 'error'
        }
      } else {
        return {
          ...item,
          status: ''
        }
      }
    })
    setSmartKeyList([...result]);
    return result.filter(i => i.status == 'error').length > 0 ? false : true
  }
  const items = [
    {
      id: "id2",
      label: "识别结果 ",
      children: (
        <>
          {
            JSON.stringify(response) !== '{}' &&
            <ErrorBoundary
              fallbackRender={(error, resetErrorBoundary ) => {
                console.log('error',error)
                return (
                   <div></div>
                );
              }}
              onReset={(details) => {
              }}
            >
              <OutputParams 
              onSelect={onSelect} 
              selectRect={selectRect} 
              paramsValue={paramsValue} 
              showDownload={detail?.resultConfig?.showDownBtn && !image.isExample} 
              resultConfig={detail.resultConfig} 
              detail={detail}
              params={response} />
            </ErrorBoundary>
          }
        </>
      ),
    },
    {
      id: "id3",
      label: "Request",
      children: (
        <Form layout="horizontal" split >
          <Form.Item label="Request信息">
            <div className="ocr-code__pre">
              <JsonView src={request} keyName={false} theme="winter-is-coming" displayDataTypes={false} />
            </div>
          </Form.Item>
        </Form>
      ),
    },
    {
      id: "id4",
      label: "Response",
      children: (
        <Form layout="horizontal" split >
          <Form.Item label="Response信息">
            <div className="ocr-code__pre">
              <JsonView src={response} keyName={false} theme="default" displayDataTypes={false} />
            </div>
          </Form.Item>
        </Form>
      ),
    }
  ];
  return (
    <div className="result-panel">
      {
        detail.paramsList?.filter(i => !i.isHide)?.length > 0 &&
        <div className={`result-panel-params__container ${showParams ? 'open' : 'close'}`} ref={paramsRef}>
          <div className="result-panel-params__content">
            <header className="unv-collapse__panel-hd cantOpen">
              <div className="unv-collapse__panel-title">参数调整</div>
              <div className="unv-collapse__panel-collops" onClick={() => {
                setShowParams(!showParams)
              }}>
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.77972 4L7.00006 6.66234L4.2204 4L3.00011 5.16883L7.00006 9L11.0001 5.16883L9.77972 4Z" fill="#32394B" />
                </svg>
              </div>
            </header>
            <div className="unv-form unv-form--split">
              <Form layout="horizontal" split>
                {
                  detail.paramsList?.filter(i => !i.isHide).map((params, index) => {
                    return <Form.Item key={index} label={params.label}>
                      <Params
                        key={params.key}
                        params={
                          {
                            ...params,
                            min: params.min ? paramsValue[params.min] : 1,
                            max: totalPage,
                          }
                        }
                        value={paramsValue[params.key]}
                        showClose={params.showClose}
                        onClose={() => {
                          console.log('onClose')
                        }}
                        onChange={(val, realVal) => {
                          setParamsValue(obj => ({ ...paramsValue, [params.key]: val }))
                          setRequestParamsValue(obj => ({ ...paramsValue, [params.key]: realVal || val }))
                          onChangeRequest && onChangeRequest(obj => ({ ...paramsValue, [params.key]: realVal || val }))
                        }}
                      />
                    </Form.Item>
                  })
                }
                {
                  smartKeyList.filter(i => !i.isHide)?.map((params, index) => {
                    return <Form.Item status="error" key={index} label={params.label || `字段${index + 1}`}>
                      <Params
                        params={{
                          ...params,
                          options: params.isCustom ? detail.options : params.options
                        }}
                        status={params.status}
                        placeholder={params.placeholder}
                        value={params.value}
                        showClose={params.showClose}
                        onClose={() => handleDelSmart(params, index)}
                        onChange={(val) => {
                          setSmartKeyList(smartKeyList.map(i => {
                            if (i.key === params.key) {
                              return {
                                ...i,
                                value: val,
                                status: reg.test(val) ? 'error' : ''
                              }
                            } else {
                              return i
                            }
                          }));
                        }} />
                    </Form.Item>
                  })
                }
                {
                  detail?.resultConfig?.type === 'smartList' && <p className="mb-20">
                    <Button disabled={isLoading} onClick={() => handleAddSmart('default')} className="ml-16" type="hole">添加默认key</Button>
                    <Button disabled={isLoading} onClick={() => handleAddSmart('custom')} className="ml-20" type="hole">添加自定义key</Button>
                  </p>
                }
                <div className="message_scroll__bar" style={{ clear: 'both', height: '1px', width: '100%' }} ref={messagesEnd}></div>

              </Form>
            </div>
          </div>
        </div>
      }
      <Tab defaultActiveId={curColl} activeId={curColl} onActive={(tab) => setCurColl(tab)}>
        {
          items.map(item => (
            <Tab.TabItem id={item.id} label={item.label} key={item.id}>
              {item.children}
            </Tab.TabItem>
          ))
        }
      </Tab>
      <footer className="unv-collapse__panel__footer">
        <Button onClick={() => handleStart(requestParamsValue)}>开始识别</Button>
      </footer>
      {isLoading && <Loading />}

    </div>
  );
}