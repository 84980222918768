/*
 * @Descripttion: http 接口
 */
import axios from 'axios';
// import { getQueryString } from './util';

const { QCLogin } = window;
const search = new URLSearchParams(location.search);
const isDev = search.get('dev');
const baseUrl = 'https://aistudio.cloud.tencent.com/' ;
// const baseUrl = 'https://ocr.ai.qq.com/ai/ocr/demo';
const notAlertCodeList = ['FailedOperation.OcrNotReadyError', 'FailedOperation.TaskClosedError', 'FailedOperation.TaskCommittedError'] ;
const service = axios.create({
  timeout: 50000,
  withCredentials: true
});

service.interceptors.request.use(
  config => config,
  error => Promise.reject(error),
);

service.interceptors.response.use(
  (response) => {
    const { code } = response.data;
    switch (code) {
      case -1004:
        console.log('error! 您无权限访问')
        break;
      case -1:
        console.log('error! ' + message)
        break;
      case -1003:
        QCLogin.showLoginBox();
        console.log('not login');
        break;
      case -2010: 
        console.log('图片包含敏感信息，请更换图片。');
        return { error: true,isImageError:true, message: '图片包含敏感信息，请更换图片。'};
        break;
      default:
        break;
    }
    return response;
  },
  error => Promise.reject(error),
);
// check response code
function checkResponseStatus(response) {
  // 304 不严谨 
  // 通用301 ，302需要处理下
  if (response.status === 200) {
    if(response.data.code === 0){
      return response.data.data.Response;
    }else{
      return { error: true, message: response.data.message};
    }
  }
  return response;
}
function cactchError(error) {
  console.log(`服务器访问出错。\n${error.message}`);
  return { error: true, message: `服务器访问出错。\n${error.message}` };
}

// check server response
function checkResponseCode(response) {
  const { Error } = response;
  if (Error) {
    // 处理云API异常
    const {
      Code, Message,
    } = Error;
    if (!notAlertCodeList.includes(Code)) {
    console.log(Message);
    }
    // 签名失败，账号过期/不同步，需要重新登录
    if (Code === 'AuthFailure.SignatureFailure') {
      QCLogin.showLoginBox();
    }

    return { error: true, message: Message, code: Code };
  }
  return response;
}
const reportEvent = (logTag, ext1, ext2, ext3) => {
  try {
    aegis.reportEvent({
      name: logTag,
      ext1,
      ext2,
      ext3,
    });
  } catch (err) {
    aegis.error(err);
  }
};

/*
 * 这里定义的接口都应该符合RESTFul规范
*/
export default {
  // normal method
  get(cmd, data) {
    return service.get(baseUrl, {
      params: {
        Action: cmd,
        Params: {
          ...data,
        },
      },
    })
      .then(res => checkResponseStatus(res))
      .then(res => checkResponseCode(res))
      .catch(cactchError);
  },
  // post request
  post({url, cmd, data,module = 'ocr'}) {
    //   上报日志
    reportEvent('OcrAction',module,cmd,JSON.stringify(data))
    return service.post(
      url,
      {
        module: module,
        action: cmd,
        from:'pc',
        userAgent: navigator.userAgent,
        payload: {
          ...data
        }
      },
    )
      .then(res => checkResponseStatus(res))
      .then(res => checkResponseCode(res))
      .catch(cactchError);
  },
  demoPost({url}){
    return service.post(
      url,
      {
        module: 'ocr',
        from:'cloud-demo'
      },
    )
      .then(response =>{
        if (response.status === 200) {
          if(response.data.code === 0){
            return response.data.data;
          }else{
            return { error: true, message: response.data.message};
          }
        }
      })
      .catch(cactchError);
  }
};


