import React, { Fragment, useContext } from "react";
import { Form, Button, Icon } from "@tencent/universe-ui/build/lib";
import ReactMarkdown from 'react-markdown'
import { OcrTable, DownloadExcel, DownloadMd, GetProperty } from '../../utils/index.js';
import { transV2 } from '../../utils/index.js';
import EduCom from './edu.jsx';
import remarkGfm from 'remark-gfm';
import isBase64 from 'is-base64';
import { Base64 } from 'js-base64';
import rehypeRaw from 'rehype-raw';
import rehypeKatex from 'rehype-katex';
import remarkMath from 'remark-math';
import { MathJax, MathJaxContext, MathJaxBaseContext } from "better-react-mathjax";
import 'katex/dist/katex.min.css'; // `rehype-katex` does not import the CSS for you
const config = {
  loader: { load: ["[tex]/html"] },
  tex: {
    packages: { "[+]": ["html"] },
    inlineMath: [
      ["$", "$"],
      ["\\(", "\\)"]
    ],
    displayMath: [
      ["$$", "$$"],
      ["\\[", "\\]"]
    ]
  }
};



const base64ToStr = (base64Str) => {
  if (isBase64(base64Str)) {
    console.log('Base64.decode(base64Str)', Base64.decode(base64Str))
    return Base64.decode(base64Str);
  }
  return base64Str;
};

function OutputParams({ detail, rectConfig, resultConfig, params, showDownload, selectRect, onSelect }) {

  const handleDownload = () => {
    DownloadExcel(params.Data);
  }
  const handleDownloadMarkdown = () => {
    const type = params.InsetImagePackage ? 'zip' : 'md';
    DownloadMd(type === 'zip' ? params.InsetImagePackage : params.MarkdownBase64, type, params.RequestId);
  }
  const handleGetWarn = () => {
    let warnList = [];
    Object.keys(params).map(key => {
      if (params[key] && params[key].IsWarn) {
        warnList.push({
          key,
          ...params[key]
        })
      }
    })
    return warnList
  }
  const getSelectRectKey = ({ data, key, valStr }) => {
    try{
      if(!data || !valStr) return '';
      if (!key) return valStr;
      let coordStr = GetProperty(data, key)
      return valStr + JSON.stringify(coordStr)
    }catch(err){
      console.log('getSelectRectKey err',err)
      return ''
    }
  }
  const handleDelParams = (params) => {
    try {

      let _params = params;
      if (JSON.stringify(_params) === '{}') return <></>;
      let paramsCon = <></>;
      if (params.error) {
        paramsCon = <div className="result_error_content">{_params.message}</div>;
      } else {
        switch (resultConfig?.type) {
          case 'Image':
            paramsCon = <>
              {
                params.Image ? <div class="ocr_tips__success">
                  <Icon size={24} name="success" color="#00b175"></Icon>
                  <span>处理成功</span>
                </div> : ''
              }</>
            break;
          case 'list':
            paramsCon = <>
              {
                params[resultConfig.firstField]?.map((i, index) => {
                  const selectRectVal = getSelectRectKey({
                    data: i,
                    key: detail?.rectConfig?.RectKey,
                    valStr: i[resultConfig.secondField]
                  });
                  return <Form.Item
                    className={`${selectRectVal && selectRect == selectRectVal ? 'isSelect' : ''} list`}
                    key={index}
                    label=""
                    onClick={() => {
                      onSelect && onSelect(i[resultConfig.secondField], selectRectVal);
                    }}
                  >
                    {i[resultConfig.secondField]}
                  </Form.Item>

                }
                )
              }
            </>
            break;
          case 'math':
            paramsCon = <>
              {
                params[resultConfig.firstField]?.map((i, index) => {
                  const selectRectVal = getSelectRectKey({
                    data: i,
                    key: detail?.rectConfig?.RectKey,
                    valStr: i[resultConfig.secondField]
                  });
                  return (
                    <Form.Item
                      className={`math-container ${selectRect && selectRect == selectRectVal ? 'isSelect' : ''} list`}
                      key={index}
                      label=""
                      onClick={() => {
                        onSelect && onSelect(i[resultConfig.secondField], selectRectVal);
                      }}
                    >
                      {
                        i[resultConfig.secondField] && <MathJaxContext version={3} config={config}>
                          <MathJax hideUntilTypeset={"first"}>
                            {i[resultConfig.secondField]}
                          </MathJax>
                        </MathJaxContext>
                      }

                    </Form.Item>
                  )
                }
                )
              }
            </>
            break;
          case 'childMap':
            // TODO: 改为递归
            _params = params;
            if (resultConfig.firstField) {
              _params = params[resultConfig.firstField];
            }
            paramsCon = <>
              {
                _params && Object.keys(_params)?.filter(i => {
                  return Object.keys(resultConfig.fieldsMap)?.includes(i)
                })?.map((key, index) => (
                  <>
                    {_params[key] && <>
                      {
                        typeof _params[key] === 'object' ? <>
                          {
                            resultConfig.fieldsMap[key]._Label && <Form.Item
                              key={index}
                              label={resultConfig.fieldsMap[key]._Label}
                            >
                            </Form.Item>
                          }
                          {
                            Object.keys(_params[key])?.filter((i, jIndex) => {
                              return Object.keys(resultConfig.fieldsMap[key]).includes(i)
                            })?.map((ckey, index) => (
                              <>
                                {_params[key][ckey] && resultConfig.fieldsMap[key][ckey] &&
                                  <Form.Item
                                    key={index}
                                    label={resultConfig.fieldsMap[key][ckey]}
                                  >
                                    {_params[key][ckey]}
                                  </Form.Item>
                                }
                              </>
                            ))
                          }
                        </> :
                          <Form.Item
                            key={index}
                            label={resultConfig.fieldsMap[key]}
                          >
                            {_params[key]}
                          </Form.Item>
                      }
                    </>
                    }
                  </>
                ))
              }
            </>
            break;
          case 'map':
            _params = params;
            if (resultConfig.firstField) {
              if (typeof resultConfig.firstField === 'string') {
                _params = params[resultConfig.firstField];
              } else {
                const key = resultConfig.firstField.filter(i => _params[i]) ? resultConfig.firstField.filter(i => _params[i])[0] : ''
                _params = _params[key];
              }
            }
            paramsCon = <>
              {
                _params && Object.keys(_params)?.filter(i => {
                  return Object.keys(resultConfig.fieldsMap)?.includes(i)
                })?.map((key, index) => (
                  <>
                    {_params[key] && <Form.Item
                      key={index}
                      label={resultConfig.fieldsMap[key]}
                    >
                      {_params[key]}
                    </Form.Item>
                    }
                  </>
                ))
              }
            </>
            break;
          case 'keyMap':
            // TODO： 和childmap合并改为递归
            paramsCon = <>
              {
                params[resultConfig.firstField]?.map((i, index) => {
                  const selectRectVal = getSelectRectKey({
                    data: i,
                    key: detail?.rectConfig?.RectKey,
                    valStr: i[resultConfig.key2 || 'Value']
                  });
                  return <>
                      {
                        resultConfig.secondField ? <>
                          {
                            i[resultConfig.secondField]?.map((v, vIndex) =>
                            (
                              <>
                                {
                                  !(resultConfig.hide && v[resultConfig.key1 || 'Name'].includes(resultConfig.hide)) &&
                                  <Form.Item
                                    className={`${selectRect == selectRectVal ? 'isSelect' : ''}`}
                                    key={vIndex}
                                    label={v[resultConfig.key1 || 'Name']}
                                    onClick={() => {
                                      console.log('select', selectRectVal, 'selectRectVal', v[resultConfig.key2 || 'Value'])
                                      onSelect && onSelect(v[resultConfig.key2 || 'Value'], selectRectVal);
                                    }}
                                  >
                                    {v[resultConfig.key2 || 'Value']}
                                  </Form.Item>
                                }
                              </>
                            )
                            )

                          }
                        </> : <>
                          {
                            !(resultConfig.hide && i[resultConfig.key1 || 'Name'].includes(resultConfig.hide)) &&
                            <Form.Item
                              key={index}
                              className={`${selectRect  && selectRect == selectRectVal ? 'isSelect' : ''}`}
                              label={i[resultConfig.key1 || 'Name']}
                              onClick={() => {
                                console.log('select', i[resultConfig.key2 || 'Value'])
                                onSelect && onSelect(i[resultConfig.key2 || 'Value'],selectRectVal);
                              }}
                            >
                              {i[resultConfig.key2 || 'Value']}
                            </Form.Item>
                          }
                        </>
                      }

                    </>
                  
                })
              }
            </>
            break;
          case 'SealOCR':
            paramsCon = <>
              {
                params[resultConfig.firstField]?.map((v, index) => 
                  {
                    const selectRectVal = getSelectRectKey({
                      data: v,
                      key: detail?.rectConfig?.RectKey,
                      valStr: v[resultConfig.secondField]
                    });
                  return <>
                    <Form.Item
                      active
                      label={`印章${index + 1}`}
                      className={`${selectRect && selectRect == selectRectVal ? 'isSelect' : ''} unv-form__item-group`}
                      onClick={() => {
                        onSelect && onSelect(v[resultConfig.secondField],selectRectVal);
                      }}
                    >
                      <p className="mb-10"><span>印章类型:</span><span>{resultConfig.SealShapeList[v.SealShape]}</span></p>
                      <p className="mb-10"><span>印章主要内容:</span><span>{v[resultConfig.secondField]}</span></p>
                      {
                        v.OtherTexts.forEach((t) => {
                          <p className="mb-10"><span>其他文本内容</span><span>{t}</span></p>
                        })
                      }
                    </Form.Item>
                  </>
              }
              )
              }
            </>
            break;
          case 'warn':
            paramsCon = <>
              {
                handleGetWarn().length > 0 ? <>
                  {
                    handleGetWarn()?.map((item, index) => (
                      <Form.Item
                        active
                        label={`告警${index + 1}`}
                        className="unv-form__item-group"
                      >
                        <p className="mb-10"><span>告警类型:</span><span>{resultConfig.warnsList[item.key]}</span></p>
                        {
                          item[resultConfig.secondField] && <p className="mb-10"><span>特殊判定:</span><span>{item[resultConfig.secondField]}</span></p>
                        }
                      </Form.Item>
                    ))
                  }
                </> : <div style={{ paddingLeft: '22px', paddingTop: '10px' }}>未检测到待告警信息</div>

              }
            </>
            break;
          case 'QrcodeOCR':
            paramsCon = <>
              {
                params.CodeResults?.map((code, index) => 
                  {
                    const selectRectVal = getSelectRectKey({
                      data: code,
                      key: detail?.rectConfig?.RectKey,
                      valStr: code.Url
                    });
                    return (
                  <div
                    className={`${selectRect &&  selectRect == selectRectVal ? 'isSelect' : ''}`}
                    key={index} onClick={() => {
                      onSelect && onSelect(code[resultConfig.key],selectRectVal);
                    }}>
                    <Form.Item
                      label="类型"
                    >
                      {code.TypeName}
                    </Form.Item>
                    <Form.Item
                      label="包含的地址"
                    >
                      {code.Url}
                    </Form.Item>
                  </div>
                )}
              )
              }
              {
                params.ImgSize && <Form.Item
                  label="图片大小"
                >
                  {`宽:${params.ImgSize.Wide}  高:${params.ImgSize.High}`}
                </Form.Item>
              }
            </>
            break;
          case "table": // type: 7表格识别
            // 接口暂不支持日文无线表格识别，若传入日文无线表格，返回0
            //  0 为非表格文本，1 为有线表格，2 为无线表格
            // 表格头部是非表格type==0，主体是表格type==1。以前都是1？
            if (params.TableDetections?.map((o) => o.Type).every((type) => type === 0)) {
              let cells = params.TableDetections?.map((item) => item.Cells).reduce((acc, curr) => {
                return acc.concat(curr);
              }, []);
              let arr = [];
              for (let v of cells) {
                arr.push(v.Text);
              }
              return arr;
            }
            const list = params.TableDetections?.filter((item) => [0, 1, 2].includes(item.Type)).sort((a, b) => a.Type - b.Type);
            const formList = list?.filter((item) => [0].includes(item.Type));
            const tableList = list?.filter((item) => [1, 2].includes(item.Type));
            if (list?.length <= 0) return '';
            let tableTem = <>
              {/* formList */}
              {
                formList?.length > 0 && formList?.map((list_item, i) => {
                  return (
                    <>
                      {
                        list_item.Cells?.map((cell, i) => (
                          <Form.Item
                            className="list"
                            key={i}
                            label=""
                          >
                            {cell.Text}
                          </Form.Item>
                        ))

                      }
                    </>
                  )
                }
                )
              }
              {/* tableList */}
              {
                tableList?.length > 0 && tableList?.map((list_item, i) => {
                  const tableData = OcrTable(list_item);
                  return (
                    <>
                      {
                        [1, 2].includes(list_item.Type) &&
                        <p class="flex just-space-between  align-center mtb-10">
                          <Button type="hole">
                            <span>表格{i + 1}</span>
                          </Button>
                          {
                            showDownload && <Button type="link" onClick={handleDownload}>导出表格</Button>
                          }
                        </p>
                      }
                      <div className="unv-table__box__container">
                        <table border="1" className="unv-table__box">
                          {
                            tableData.length > 0 && tableData?.map((tr, tri) => {
                              return (
                                <>
                                  <tr key={tri}>
                                    {
                                      tr.length > 0 && tr?.map((td, tdi) => (
                                        // <td key={tdi} colSpan={td.occupyCol} rowSpan={td.occupyRow}>{td.cell_content_text.replace('\n', '<br />')}</td>
                                        <td key={tdi} colSpan={td.occupyCol} rowSpan={td.occupyRow}>{td.cell_content_text}</td>
                                      ))
                                    }
                                  </tr>
                                </>
                              )
                            })
                          }
                        </table>
                      </div>

                    </>
                  );

                })
              }
            </>;
            paramsCon = tableTem;
            break;
          case "smartList": // 智能结构化
            let smartListType = detail?.rectConfig?.useWordList && params?.WordList?.length > 0 ? 'WordList' : 'StructuralList'
            if(smartListType === 'WordList'){
              paramsCon = <>
              {
                params.WordList?.map((i, index) => {
                  const selectRectVal = getSelectRectKey({
                    data: i,
                    key: 'Coord',
                    valStr: i.DetectedText
                  });
                  return <Form.Item
                    className={`${selectRectVal && selectRect == selectRectVal ? 'isSelect' : ''} list`}
                    key={index}
                    label=""
                    onClick={() => {
                      onSelect && onSelect(i.DetectedText, selectRectVal);
                    }}
                  >
                    {i.DetectedText}
                  </Form.Item>

                }
                )
              }
            </>
            }else if(smartListType === 'StructuralList') {
              _params = transV2(params?.StructuralList);
              paramsCon = <>
                {
                  _params?.list?.length > 0 &&
                  <>
                    {_params.list.map((item, index) => {
                      const selectRectVal = getSelectRectKey({
                        data: item,
                        key: 'originItemCoord',
                        valStr: item.Value
                      });
                      return (
                      <Form.Item
                        key={index}
                        label={item.Name}
                        onClick={() => {
                          console.log('selectRectVal',selectRectVal,'selectRect',selectRect)
                          onSelect && onSelect(item.Value,selectRectVal);
                        }}
                        className={`${selectRect && selectRect == selectRectVal ? 'isSelect' : ''} `}
                      >
                        {item.Value}
                      </Form.Item>
                    )}
                    )}
                  </>
                }
                {_params.table.map((item, index) => (
                  <>
                    <p class="flex just-space-between  align-center mtb-10">
                      <Button type="hole">
                        <span>表格{index + 1}</span>
                      </Button>
                    </p>
                    <div className="unv-table__container">
                      <table style={{ minWidth: item.header.length * 100 }}>
                        <tbody>
                          {/* 表格header */}
                          <tr>
                            {
                              item.header.map(header => (
                                <td style={{ width: '100px' }} >{header}</td>
                              ))
                            }
                          </tr>
                          {
                            item.content.map((line, j) => (
                              <tr>
                                {
                                  line.map(tr => (
                                    <td style={{ width: '100px' }} >{tr}</td>
                                  ))
                                }
                              </tr>
                            ))
                          }
  
                        </tbody>
                      </table>
                    </div>
                  </>
                )
                )}
              </>
            }
            
            break;
          case "invoice": // 票据识别
            paramsCon = <>
              {
                params[resultConfig.firstField]?.map((v, index) => {
                  let OtherTexts = v.SingleInvoiceInfos[v.SubType];
                  const selectRectVal = getSelectRectKey({
                    data: v,
                    key: detail?.rectConfig?.RectKey,
                    valStr: v.SubType
                  });
                  return <div
                    onClick={() => {
                      onSelect && onSelect(v.SubType,selectRectVal);
                    }}
                    className={`${selectRect  && selectRect == selectRectVal ? 'isSelect' : ''} invoiceContent`}
                  >
                    <Form.Item
                      active
                      label={`票据类型`}
                      className="unv-form__item-group"
                    >
                      {resultConfig.invoiceTypeMap[v.Type]}
                    </Form.Item>
                    <Form.Item
                      active
                      label={`旋转角度`}
                      className="unv-form__item-group"
                    >
                      {v.Angle}
                    </Form.Item>
                    <Form.Item
                      active
                      label={`位置信息`}
                      className="unv-form__item-group"
                    >
                      {JSON.stringify(v.Polygon)}
                    </Form.Item>
                    {
                      OtherTexts &&
                      <>
                        {
                          Object.keys(OtherTexts)?.map((k, index) => {
                            if (OtherTexts[k] !== '' && !(OtherTexts[k] instanceof Object)) {
                              return <Form.Item
                                active
                                label={`${resultConfig.fieldsMap[k]}`}
                                className="unv-form__item-group"
                              >
                                {OtherTexts[k]}
                              </Form.Item>
                              // <p key={index} className="mb-10"><span><b>{resultConfig.fieldsMap[k]}: </b></span><span>{OtherTexts[k]}</span></p>
                            } else if ((OtherTexts[k] instanceof Object)) {
                              return <>
                                <Form.Item
                                  active
                                  label={`其他字段`}
                                  className="unv-form__item-group"
                                >
                                  {/* <p className="mb-10"><span><b>其他字段: </b></span></p> */}
                                  {
                                    Object.keys(OtherTexts[k])?.map((o, i) => {
                                      return <p key={i} className="mb-10"><span><b>{o.Name}: </b></span><span>{o.Value}</span></p>
                                    })
                                  }
                                </Form.Item>
                              </>
                            }
                          })
                        }
                      </>
                    }
                  </div>
                })
              }
            </>
            break;
          case 'markdown':
            paramsCon = <>{
              params.MarkdownBase64 && <>
                <p class="flex just-space-between  align-center mtb-10">
                  <Button type="hole">
                    <span>Markdown结果</span>
                  </Button>
                  <Button type="link" onClick={handleDownloadMarkdown}>
                    <Icon name="download" size={14} style={{ marginRight: 2 }}></Icon>
                    <span>下载Markdown</span>
                  </Button>
                </p>
                <div className="markdown_container" style={{ height: 'calc(100% - 50px)', overflow: 'auto' }}>
                  <ReactMarkdown remarkPlugins={[remarkGfm, remarkMath]} rehypePlugins={[rehypeRaw, rehypeKatex]}>
                    {base64ToStr(params.MarkdownBase64)}
                  </ReactMarkdown>
                </div>
              </>
            }

            </>
            break;
          case 'store':
            paramsCon = <>
              <Form.Item label="图片类型">
                {params.StoreLabel?.includes('标准门头照') ? '标准门头照' : '非标准门头照'}
              </Form.Item>
              {
                params.StoreInfo?.map((i, index) => {
                  const selectRectVal = getSelectRectKey({
                    data: i,
                    key: detail?.rectConfig?.RectKey,
                    valStr: i.Value,selectRectVal
                  });
                  return (
                  <Form.Item
                    className={`${selectRectVal  && selectRect == selectRectVal ? 'isSelect' : ''}`}
                    key={index}
                    label={i.Name}
                    onClick={() => {
                      console.log('store',selectRect,i)
                      onSelect && onSelect(i.Value,selectRectVal);
                    }}
                  >
                    {i.Value}
                  </Form.Item>
                )
        })
              }
            </>
            break;
          case 'edu':
            paramsCon = <EduCom selectRect={selectRect} questionInfo={params.QuestionInfo} onSelect={(i) => onSelect && onSelect(i,i)} />
            break;
        }
      }

      return paramsCon
    } catch (e) {
      return <></>
    }

  }
  return (
    <Form layout="horizontal" split>
      {handleDelParams(params)}
    </Form>
  );
}

export default OutputParams;

