import React, { useState, useEffect, useRef } from "react";
import { Form, Button, Icon } from "@tencent/universe-ui/build/lib";
import { transEdu } from '../../utils/index';
import { MathJax, MathJaxContext } from "better-react-mathjax";

const config = {
  loader: { load: ["[tex]/html"] },
  tex: {
    packages: { "[+]": ["html"] },
    inlineMath: [
      ["$", "$"],
      ["\\(", "\\)"]
    ],
    displayMath: [
      ["$$", "$$"],
      ["\\[", "\\]"]
    ]
  }
};
const TypeMap = {
  'Question': '题干',
  'Option': '选项',
  'Figure': '插图',
  'Table': '表格',
  'Answer': '答案'
}

const GroupTypeMap = {
  'multiple-choice': '选择题',
  'fill-in-the-blank': '填空题',
  'problem-solving': '解答题',
  'arithmetic': '算术题'
}



function EduCom({ questionInfo, dep = 1, onSelect, selectRect }) {
  const [collList, setCollList] = useState([]);

  return (
    <div className="edu_container">
      {
        questionInfo && questionInfo[0] && questionInfo[0].ResultList?.length > 0 && questionInfo[0].ResultList.map((question, questionId) => {
          let params = transEdu(question);
          return <div style={{ marginLeft: `${dep * 16}px` }} className={`${dep > 1 ? 'isSub' : ''} ${collList.includes(questionId) ? 'isHide' : 'isActive'} ${`Question${questionId.toString()}${dep.toString()}` === selectRect ? 'isChecked' : ''} edu_container__item`}>
            <div className="edu_title_container" onClick={() => {
              // onSelect 和 rect规则对应
              onSelect && onSelect(`Question${questionId.toString()}${dep.toString()}`)
            }}>
              <span className="edu_title__tips"></span>
              <span className="edu_title__text">
                {dep > 1 ? '子' : ''}题目{questionId + 1}

              </span>
              <span className="edu_title__collaps" onClick={(event) => {
                event.stopPropagation();
                let _collList = collList;
                if (collList.includes(questionId)) {
                  _collList.splice(_collList.indexOf(questionId), 1)
                } else {
                  _collList.push(questionId)
                }
                setCollList(_collList.concat([]))
              }}>
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.77971 9L7.00005 6.33766L4.22039 9L3.0001 7.83117L7.00005 4L11 7.83117L9.77971 9Z" fill="#32394B" />
                </svg>

              </span>
            </div>
            <div className="edu_body_container">
              {/* TODO: 顺序改为固定题干、选项、插图、table、答案 */}
              {
                params.map((detail, dIdx) => {
                  return <>
                    {
                      detail?.GroupType && detail.type === 'Question' &&
                      <Form.Item
                        label="题目类型"
                      >
                        {GroupTypeMap[detail?.GroupType]}
                      </Form.Item>
                    }
                    {
                      TypeMap[detail.type] && <Form.Item
                        className={`${`result${questionId}${detail.type}${detail.Index.toString()}${dep.toString()}` === selectRect ? 'isChecked' : ''}`}
                        label={detail.type === 'Figure' ? `${TypeMap[detail.type]}${detail.dIdx + 1}` : TypeMap[detail.type]}
                        onClick={(event) => {
                          // onSelect 和 rect规则对应
                          onSelect && onSelect(`result${questionId}${detail.type}${detail.Index.toString()}${dep.toString()}`)
                        }}
                      >
                        <div className="edu_form__item">
                          {detail.type === 'Figure' && <span className="edu_form__item--figure">
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" fill="#F4F5F7" />
                              <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="#E0E2E7" />
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1005 13.4054C20.8136 13.4054 21.3917 12.8246 21.3917 12.1081C21.3917 11.3916 20.8136 10.8108 20.1005 10.8108C19.3874 10.8108 18.8094 11.3916 18.8094 12.1081C18.8094 12.8246 19.3874 13.4054 20.1005 13.4054ZM13.1789 13.2864C13.3504 13.0328 13.7238 13.0328 13.8953 13.2864L17.6414 18.8257L19.6862 15.8597C19.8566 15.6126 20.2207 15.6099 20.3946 15.8545L23.7033 20.5061C23.907 20.7924 23.7023 21.1892 23.3509 21.1892H18.4253H16.0119H8.64899C8.30231 21.1892 8.09657 20.8017 8.29078 20.5145L13.1789 13.2864Z" fill="#CDD0D9" />
                            </svg>

                          </span>}
                          {
                            detail?.Text && <MathJaxContext version={3} config={config}>
                            <MathJax hideUntilTypeset={"first"}>
                              {detail.Text}
                            </MathJax>
                          </MathJaxContext>
                          }
                          
                        </div>

                      </Form.Item>
                    }
                    {
                      detail.ResultList?.length > 0 &&
                      <>
                        <EduCom selectRect={selectRect} onSelect={onSelect} questionInfo={[{ ResultList: detail.ResultList }]} dep={dep + 1} />
                      </>

                    }
                  </>
                })
              }
            </div>
          </div>
        }
        )
      }
    </div>
  );
}

export default EduCom;